:root {
  --white: #ffffff;
  --themeColorDisable: rgb(212, 187, 133);
  --themeColor: #dce695;
  --themeText: #000000;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.dark {
  --bg: #15171b;
  --textColor: #ffffff;
  --softBg: #0f1118;
  --softTextColor: #ffffff;


}

.light {
  --bg: white;
  --textColor: black;
  --softBg: #f0f0f0;
  --softTextColor: #ffffff;

}

.light .box-shadow{
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dark .box-shadow{
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.1);
}

.button-rounded{
  border-radius: 10px;
}

.components{
  margin: 20px 0;
}

.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.themecom{
  background-color: var(--themeColor);
  color: var(--themeText);
}

.border5{
  border-radius: 5px;
}

.container {
  min-height: 100vh;
  background-color: var(--bg);
  color: var(--textColor);
}

.wrapper {
  max-width: 1536px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 80px;
}

.theme{
  color:var(--themeColor);
}

@media screen and (max-width: 1536px) {
  .wrapper {
    max-width: 1366px;
  }
}
@media screen and (max-width: 1280px) {
  .wrapper {
    max-width: 1024px;
  }
}
@media screen and (max-width: 1024px) {
  .wrapper {
    max-width: 768px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 768px) {
  .wrapper {
    max-width: 640px;
  }
}
@media screen and (max-width: 640px) {
  .wrapper {
    max-width: 600px;
    padding-left: 20px;
    padding-right:20px;
  }
}

.dart {
  font-family: inherit;
  cursor: pointer;
  position: relative;
  border: none;
  background: inherit;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
  z-index: 0;
  width: max-content;
}

.dart:focus:after,
.dart:hover:after {
  width: 100%;
  left: 0%;
}

.dart:after {
  content: "";
  pointer-events: none;
  bottom: -0.125rem;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 0.125rem;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
  margin-bottom: -0.1875rem;
  background-color: var(--themeColor);
}



.darts {
  font-family: inherit;
  cursor: pointer;
  position: relative;
  border: none;
  background: inherit;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
  z-index: 0;
  width: auto; /* Change max-content to auto */
  display: inline-block; /* Add this line */
  white-space: normal; /* Add this line */
}

.darts:focus:after,
.darts:hover:after {
  width: 100%;
  left: 0%;
  white-space: normal;
}

.darts:after {
  content: "";
  pointer-events: none;
  bottom: -0.125rem;
  left: 50%;
  position: absolute;
  width: auto; 
  height: 0.125rem;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, top;
  margin-bottom: -0.1875rem;
  background-color: var(--themeColor);
  white-space: normal;

}

.loading-wave {
  width: 300px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.loading-bar {
  width: 20px;
  height: 10px;
  margin: 0 5px;
  background-color: var(--textColor);
  border-radius: 5px;
  animation: loading-wave-animation 1s ease-in-out infinite;
}

.loading-bar:nth-child(2) {
  animation-delay: 0.1s;
}

.loading-bar:nth-child(3) {
  animation-delay: 0.2s;
}

.loading-bar:nth-child(4) {
  animation-delay: 0.3s;
}

@keyframes loading-wave-animation {
  0% {
    height: 10px;
  }

  50% {
    height: 50px;
  }

  100% {
    height: 10px;
  }
}

.block{
  display: block;
}

.flex{
  display: flex;
}


.sc-checkbox {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: transparent;
  display: inline-block;
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}
.sc-checkbox {
  border: 2px solid var(--themeColor);
}

.sc-checkbox:before {
  content: "";
  display: block;
  position: relative;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  width: 8px;
  height: 8px;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.sc-checkbox:before {
  background-color: var(--themeColor);
}

.sc-checkbox:checked:before {
  transform: translate(-50%, -50%) scale(1);
}