/* --------------------------   ALL START           -------- ------------- */
.general-container {
  min-height: 100px;
}

.general-card-class {
  margin: 50px 0;
}

.lp-checkbox {
  width: 15px;
  height: 15px;
}
.themetoggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: var(--textColor);
  border-radius: 50%;
}

.theme {
  background-color: var(--bg);
  color: var(--textColor);
}

.scrolltotop-container {
  font-size: 1.625rem;
  background-color: var(--themeColor);
  padding: 5px 10px 0px 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: var(--textColor);
}

.inline-block {
  display: inline-block;
}

/* --------------------------   ALL END        -------- ------------- */

/* --------------------------   NAVBAR START           -------- ------------- */

.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  /* position: fixed;
  top: 0;
  left: inherit;
  right: inherit;
  z-index: 100000; */
}

.navbar-logo-name {
  display: flex;
  justify-content: space-between;
  gap: 15px;

  align-items: center;
}

.navbar-logo-img {
  object-fit: cover;
}

.logo-name {
  background-color: var(--themeColor);
  color: var(--themeText);
  padding: 5px;
  border-radius: 5px;
  font-weight: 200px;
  font-size: 1.1rem;
}

.navbar-logo {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.navbar-blogname {
  text-align: center;
  font-size: 1.875rem;
  font-weight: bold;
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 0.9375rem;
  text-transform: uppercase;
  font-weight: bold;
}

.navbar-link {
  cursor: pointer;
}

.navbar-link:hover {
  color: var(--themeColor);
}

@media screen and (max-width: 1280px) {
  .navbar-blogname {
    font-size: 1.75rem;
  }
  .navbar-links {
    font-size: 0.8125rem;
    gap: 14px;
  }
}
@media screen and (max-width: 1024px) {
  .navbar-blogname {
    font-size: 1.5625rem;
  }
}
@media screen and (max-width: 768px) {
  .navbar-blogname {
    font-size: 1.25rem;
  }

  .navbar-logo-img {
    gap: 5px;
  }

  .navbar-links {
    font-size: 0.8125rem;
    gap: 12px;
  }
}
@media screen and (max-width: 640px) {
  .navbar-links {
    justify-content: flex-end;
  }
  .navbar-link {
    display: none;
  }
}

/* --------------------------   NAVBAR END          -------- ------------- */

.navbar-burger {
  width: 20px;
  height: 16px;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  display: none;
}

.navbar-line {
  width: 100%;
  height: 2px;
  background-color: var(--textColor);
}

.navbar-sidenav {
  visibility: hidden;
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  overflow: auto;
  padding-top: 0;
  margin: 0;
  background-color: var(--bg);
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  transition: all 0.4s ease-in;
}

.navbar-open {
  width: 250px;
  visibility: visible;
}

.navbar-close {
  font-size: 1.5625rem;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin: 20px;
}

.navbar-closechild {
  width: max-content;
  border: solid 1px var(--textColor);
  padding: 0px 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-sidelink {
  font-size: 1rem;
  cursor: pointer;
}

.navbar-sidelink:hover {
  color: var(--themeColor);
}

.navbar-close:hover {
  color: var(--themeColor);
}

.navbar-closechild:hover {
  border: solid 1px var(--themeColor);
}

@media screen and (max-width: 640px) {
  .navbar-burger {
    display: flex;
  }

  .navbar-link {
    display: none;
  }
}

.grid-center {
  display: grid;
  place-items: center;
}

.sidelink-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  padding: 20px 20px;
  border-radius: 8px;
  margin-bottom: 5px;
  width: 100%;
}

.sidelink-container:hover {
  opacity: 60%;
}

/* --------------------------   AUTHLINKS END          -------- ------------- */
.footer-container {
  padding: 30px 0;
  background-color: var(--bg);
}

.footer-parent {
  padding-top: 10px;
  color: var(--themeText);
  background-color: var(--themeColor);
  border-radius: 15px;
}

.footer-start-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (min-width: 850px) {
  .footer-start {
    margin: 0 250px;
  }
}

.footer-start {
  margin: 0 50px;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.footer-start-title {
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 15px;
}

.footer-start-text {
  font-size: 1rem;
  margin-bottom: 10px;
}

.footer-start-input {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.footer-input {
  padding: 8px 10px;
  margin-right: 5px;
  border: none;
}

.footer-input:focus {
  outline: none;
}

.footer-input-Form:focus-within {
  border: 2px solid var(--themeText);
}

.footer-button {
  padding: 8px 10px;
  border: 1px solid var(--themeText);
  border-radius: 5px;
  background-color: var(--white);
  cursor: pointer;
}

.footer-button:hover {
  background-color: var(--themeColor);
  color: var(--themeText);
}

.footer-input-Form {
  border: 1.5px solid var(--themeText);
  border-radius: 5px;
  padding: 5px 10px;
}

.footer-end {
  border-top: 2px solid var(--white);
  padding: 20px;
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9375rem;
  font-weight: normal;
}

/* -------------------------- FOOTER   END          -------- ------------- */

/* --------------------------    END          -------- ------------- */

.cookie-consent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: var(--bg);
  color: var(--textColor);
  padding: 15px 20px;
  text-align: center;
  border-top: 1px solid var(--themeColor);
}

@media screen and (max-width: 1536px) {
  .cookie-consent {
    padding: 15px 80px;
  }
}
@media screen and (max-width: 1280px) {
  .cookie-consent {
    padding: 15px 150px;
  }
}
@media screen and (max-width: 1024px) {
  .cookie-consent {
    padding: 15px 110px;
  }
}
@media screen and (max-width: 768px) {
  .cookie-consent {
    padding: 15px 20px;
  }
}

.cookie-privacy {
  font: bold;
  text-decoration: underline;
}

.log-button {
  padding: 5px;
  width: max-content;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  margin: 10px 0;
  cursor: pointer;
  background-color: var(--themeColor);
  color: var(--themeText);
}

.log-button:hover {
  opacity: 65%;
}

.cookie-consent button {
  color: var(--textColor);
  border: 1px solid var(--themeColor);
  background-color: var(--bg);
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.cookie-consent button:hover {
  background-color: var(--themeColor);
  color: var(--themeText);
}

/* ............................................................ */

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: var(--bg);
  color: var(--textColor);
  padding: 30px;
  width: 450px;
  margin: 15px 0;
  border: 1px solid var(--textColor);
  border-radius: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

::placeholder {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.form button {
  align-self: flex-end;
}

.flex-column > label {
  color: var(--textColor);
  font-weight: 600;
}

.input-label {
  padding: 10px 0;
}

.inputForm {
  border: 1.5px solid #ecedec;
  border-radius: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  transition: 0.2s ease-in-out;
  margin-top: 10px;
}

.input {
  margin-left: 10px;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 100%;
}

.input:focus {
  outline: none;
}

.inputForm:focus-within {
  border: 1.5px solid var(--themeColor);
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.flex-row > div > label {
  font-size: 0.875rem;
  color: var(--textColor);
  font-weight: 400;
}

.span {
  font-size: 0.875rem;
  margin-left: 5px;
  color: var(--themeColor);
  font-weight: 500;
  cursor: pointer;
}

.button-submit {
  margin: 20px 0 10px 0;
  background-color: var(--themeColor);
  color: var(--themeText);
  font-size: 0.9375rem;
  font-weight: 500;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  border: none;
  cursor: pointer;
}

.button-submit:hover {
  opacity: 65%;
}

.p {
  text-align: center;
  color: var(--textColor);
  font-size: 0.875rem;
  margin: 5px 0;
}

.btn {
  margin-top: 10px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  gap: 10px;
  border: 1px solid var(--themeColor);
  background-color: var(--bg);
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-title {
  font-size: 1.625rem;
  font-weight: bold;
  margin: 25px 0 20px 0;
  color: var(--themeColor);
}

.radio-flex {
  display: flex;
  gap: 5px;
}

.board-card {
  background-color: var(--themeColor);
  color: var(--themeText);
  width: 100%;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
}

.board-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 10px 0;
}

.board-text {
  font-size: 1rem;
  font-weight: normal;
}

.image {
  width: 100%;
  object-fit: cover;
  height: auto;
  border-radius: 5px;
  margin: 10px 0;
}

.stepper-container {
  display: grid;
  place-items: center;
  height: auto;
  background-color: var(--bg);
  color: var(--textColor);
}

.stepper-container .step {
  width: 300px;
}

.stepper-container input {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid var(--textColor);
  border-radius: 5px;
}
.stepper-container textarea {
  width: 100%;
  min-height: 80px;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid var(--textColor);
  border-radius: 5px;
}

.capitalize {
  text-transform: capitalize;
}
.stepper-container label {
  padding-bottom: 12px;
  font-size: 14px;
}

.stepper-container select {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid var(--textColor);
  border-radius: 5px;
}
.stepper-container h4 {
  margin-bottom: 10px;
}

.preview-container {
  display: flex;
  flex-direction: column;
}

.image-container-scope {
  display: flex;
  gap: 5px;
}

.preview-pfp {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.stepper-container button {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  background-color: var(--themeColor);
  color: var(--themeText);
  cursor: pointer;
}

.stepper-container button:hover {
  opacity: 65%;
}

.stepper-container h2 {
  text-align: center;
}

.btn-flex {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
}

.flex-col {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.manage-link-card {
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  width: 450px;
  text-align: center;
}

.manage-link-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.manage-link-title {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.manage-link-text {
  font-size: 0.875rem;
  margin-bottom: 4px;
}

.input-search {
  padding: 5px 8px;
  border: 1px solid var(--textColor);
  border-radius: 12px;
}

.input-search:focus {
  outline: 1px solid var(--themeColor);
  border: 1px solid var(--themeColor);
}

.search-engine-container {
  display: flex;
  gap: 4px;
}

.search-icon-theme {
  background-color: var(--themeColor);
  color: var(--themeText);
  border-radius: 38%;
  padding: 8px;
  width: max-content;
}

.matrimony-container {
  margin: 50px 0;
}

.matrimony-first {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.button-icon {
  padding: 5px 8px;
  border-style: none;
  background-color: var(--themeColor);
  color: var(--themeText);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.button-icon:hover {
  opacity: 65%;
}

.profile-card-container {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.profile-card {
  padding: 10px 30px;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
}

.profile-card-details {
  width: 100%;
  height: auto;
  margin: 20px 0;
}

.profile-info {
  margin-top: 10px;
}

.delete-icon {
  width: 100%;
  text-align: center;
  padding: 15px 0;
  font-size: 15px;
  border: 1px solid var(--themeColor);
  color: var(--textColor);
  border-radius: 8px;
  margin: 10px 0;
  cursor: pointer;
}

.delete-icon:hover {
  background-color: var(--themeColor);
  color: var(--themeText);
}

.profile-imag {
  width: 100%;
  height: 420px;
  object-fit: cover;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-star-icon {
  display: flex;
  justify-content: end;
}

.profile-flex {
  display: flex;
  justify-content: space-between;
  align-content: center;
  gap: 10px;
  margin-top: 10px;
}

.profile-flex-info {
  display: flex;
  gap: 5px;
  margin-bottom: 3px;
}
.profile-title {
  font-size: 0.875rem;
  font-weight: bold;
  margin-bottom: 10px;
}
.profile-subtitle {
  font-size: 0.875rem;
}

.confirm-container {
  padding: 20px;
  margin: 20px 0;
}

.confirm-text {
  margin-bottom: 20px;
}

.confirm-button {
  margin: 30px 0;
  padding: 10px 15px;
  background-color: var(--themeColor);
  color: var(--themeText);
  border-radius: 5px;
}

.confirm-button:hover {
  opacity: 65%;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
}

.pro-til{
  margin: 8px 0;
  color: var(--themeColor);
  font-size: large;
  font-weight: 700;
}

.profile-container {
  display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-template-rows: max-content;
      gap: 20px;
}

.profile-header {
  margin-bottom: 10px;
  padding: 20px;
  border-radius: 8px;
  height: max-content;
}

.container-next-prev{ 
  display: flex;
  align-items: center;
  margin:5px 0;
  gap: 5px;
}


.next-prev{
padding: 5px 10px;
background-color: var(--themeColor);
color: var(--themeText);
cursor: pointer;
}

.next-prev:hover{
  opacity: 65%;
}

.profile-image-dot {
  width: 100%;
  object-fit: cover;
  height: 750px;
  border-radius: 8px;
  margin-top: 10px;
}

.profile-header-info {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-name {
  margin: 0;
  font-size: 2.1875rem;
  font-weight: bold;
}

.profile-age {
  margin: 0;
  font-size: 1.5625rem;
  font-weight: bold;
}

.profile-last-online {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;
}

.profile-details {
  padding: 20px;
  border-radius: 8px;
}

.profile-status {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  text-align: center;
  gap: 15px;
  height: 50px;
  font-weight: bold;
  font-size: 1.125rem;
}

.profile-info-1 {
  margin: 20px 0;
}

.profile-gang {
  display: flex;
  justify-content: start;
  gap: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.profile-title {
  font-size: 0.9rem;
  font-weight: 400px;
}

.profile-subtitle {
  font-size: 1.125rem;
  font-weight: 300px;
}

.profile-about {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.block-back {
  display: flex;
  justify-content: end;
  align-items: center;
}

.all-back-button {
  margin: 10px 0;
  padding: 5px 8px;
  background-color: var(--themeColor);
  color: var(--themeText);
  width: max-content;
  border-radius: 6px;
  cursor: pointer;
}

.all-back-button:hover {
  opacity: 65%;
}

.faq-item {
  border: 1px solid var(--textColor);
  border-radius: 4px;
  margin-bottom: 5px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  padding: 3px 6px;
}

.faq-answer {
  padding-left: 5px;
  margin: 5px 0;
  font-size: 13px;
}

.table-container {
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.allsvgclass {
  display: grid;
  place-items: center;
}
/* .svgclass{
  width: 300px;
} */

.table th,
.table td {
  border: 1px solid var(--textColor);
  padding: 8px;
  font-size: 12px;
}

.table th {
  background-color: var(--bg);
  text-align: left;
}

.table th:last-child,
.table td:last-child {
  text-align: center;
}

.table button {
  padding: 10px 15px;
  background-color: var(--themeColor);
  color: var(--themeText);
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.table button:hover {
  opacity: 65%;
}

.table-last-container {
  margin: 15px 0;
}

.pagination {
  display: flex;
  gap: 8px;
  align-items: center;
}

.pagination button {
  padding: 5px 8px;
  background-color: var(--themeColor);
  color: var(--themeText);
  border-radius: 5px;
  border: none;
}

.pagination button:hover {
  opacity: 65%;
}

.table-current-total {
  margin-bottom: 8px;
  font-size: 16px;
}

.manage-search {
  margin: 15px 0;
  display: grid;
  place-items: center;
}

.manage-info-input {
  margin-bottom: 10px;
}

.manage-info-input label {
  display: block;
  margin-bottom: 5px;
}

.manage-info-input input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.manage-info-button {
  width: 100%;
  padding: 10px;
  background-color: var(--themeColor);
  color: var(--themeText);
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.manage-info-button:hover {
  opacity: 65%;
}

.button-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 50%;
  margin: 15px 0;
}

.add-image {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  overflow: hidden;
}

.add-image input[type="file"] {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.label-stepper {
  margin-bottom: 5px;
}

.add-image span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 28px;
  color: #ccc;
  cursor: pointer;
}

.remove-btn {
  font-size: 12px;
  display: grid;
  place-items: center;
  width: 15px;
  height: 15px;
  border: 1px solid var(--textColor);
  border-radius: 50%;
  cursor: pointer;
}

.input-btn {
  padding: 6px 8px;
}

.button-input-class {
  padding: 5px 10px;
  background-color: var(--themeColor);
  color: var(--themeText);
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-size: 12px;
}

.button-input-class:hover {
  opacity: 65%;
}

.spinner {
  font-size: 28px;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
}

.spinner.center {
  position: absolute;
}

.spinner .spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.0555em;
  background-color: transparent;
  -webkit-transform-origin: center -0.2222em;
  -ms-transform-origin: center -0.2222em;
  transform-origin: center -0.2222em;
  animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
  -webkit-animation-delay: 0.083s;
  animation-delay: 0.083s;
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
  -webkit-animation-delay: 0.166s;
  animation-delay: 0.166s;
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
  -webkit-animation-delay: 0.249s;
  animation-delay: 0.249s;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
  -webkit-animation-delay: 0.332s;
  animation-delay: 0.332s;
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
  -webkit-animation-delay: 0.415s;
  animation-delay: 0.415s;
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
  -webkit-animation-delay: 0.498s;
  animation-delay: 0.498s;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
  -webkit-animation-delay: 0.581s;
  animation-delay: 0.581s;
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
  -webkit-animation-delay: 0.664s;
  animation-delay: 0.664s;
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
  -webkit-animation-delay: 0.747s;
  animation-delay: 0.747s;
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
  -webkit-animation-delay: 0.83s;
  animation-delay: 0.83s;
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
  -webkit-animation-delay: 0.913s;
  animation-delay: 0.913s;
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
  0% {
    background-color: #69717d;
  }

  100% {
    background-color: transparent;
  }
}

/* ----------------------------all  mobile ---------------------------------- */

@media screen and (max-width: 640px) {
  .button-input-container {
    width: 100%;
  }
  .profile-image-dot {
    height: 650px;
  }

  .profile-container {
    display: block;
  }
  .general-card-class {
    margin: 20px 0;
  }

  .profile-card-container {
    display: block;
    width: 100%;
  }

  .matrimony-first {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }
  .board-card {
    padding: 8px;
  }

  .board-title {
    font-size: 1rem;
  }

  .board-text {
    font-size: 0.75rem;
  }

  .footer-end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px;
    font-size: 0.875rem;
  }

  .footer-start {
    margin: 0 10px;
  }

  .footer-start-text {
    font-size: 0.875rem;
  }

  .footer-start-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .footer-parent {
    padding-top: 50px;
  }
  .manage-link-card {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
  }
  .bold-name {
    font-weight: bold;
    font-size: 1.125rem;
  }

  .form {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 10px;
  }
  .form-container {
    display: block;
    margin: 0 10px;
  }
}
